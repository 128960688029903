<template>
<div>
    <v-row>
        <v-col>
            <s-crud add remove no-full @save="save($event)" searchInput title="Proveedores" :config="config" :filter="filter" @rowSelected="rowSelected($event)">
                <template scope="props">
                    <!-- <v-col> -->
                    <v-row justify="center">
                        <v-col cols="6" class="s-col-form">
                            <s-text v-model="props.item.ProviderRUC" ref="ProviderRUC" label="Ruc" @keyupEnter="searchSunarp(props.item)">
                                <template v-slot:prepend>
                                    <v-icon @click="searchSunarp(props.item)">mdi-magnify</v-icon>
                                </template>
                            </s-text>
                        </v-col>
                        <v-col cols="6" class="s-col-form">
                            <s-text v-model="props.item.SupName" ref="SupName" label="Proveedor"> </s-text>
                        </v-col>
                        <v-col lg="12" md="12" cols="12" class="s-col-form">
                            <s-textarea v-model="props.item.ProviderAddress" ref="ProviderAddress" label="Dirección"> </s-textarea>
                        </v-col>
                        <v-col lg="4" md="4" cols="4" class="s-col-form">
                            <s-text label="Contacto" ref="ConName" v-model="props.item.ConName"></s-text>
                        </v-col>
                        <v-col lg="4" md="4" cols="4" class="s-col-form">
                            <s-text label="Email" ref="ConEmail" v-model="props.item.ConEmail"></s-text>
                        </v-col>
                        <v-col lg="4" md="4" cols="4" class="s-col-form">
                            <s-text label="Celular" ref="ConTelephone" number v-model="props.item.ConTelephone"></s-text>
                        </v-col>
                        <v-col cols="12" class="s-col-form">

                        </v-col>
                    </v-row>
                    <!-- <v-row justify="center">
                        <v-card outlined width="100%" style="margin-top:10px">
                            <v-card-title style="margin-top: -10px; margin-bottom: -10px; font-family: Calibri">
                                Datos Bancarios
                            </v-card-title>
                        </v-card>
                        <v-card outlined width="100%" style="margin-top:10px;" class="pb-4">
                            <v-row justify="center" style="margin:auto">
                                <v-col cols="4" class="s-col-form">
                                    <s-select-definition label="Banco" :def="1176" return-object v-model="itemBank.objBank"></s-select-definition>
                                </v-col>
                                <v-col cols="4" class="s-col-form">
                                    <s-text v-model="itemBank.PbkBill" ref="PbkBill" label="Cuenta"> </s-text>
                                </v-col>
                                <v-col cols="4" class="s-col-form">
                                    <s-text v-model="itemBank.PbkCci" ref="PbkCci" label="CCI"> </s-text>
                                </v-col>
                            </v-row>

                        </v-card>
                        <v-card outlined width="100%" style="margin-top:10px">
                            <v-btn icon @click="add()">
                                <v-icon>fas fa-plus</v-icon>
                            </v-btn>
                            <v-data-table class="elevation-0 table table-hover table-condensed" outlined disable-sort dense hide-default-footer :items-per-page="-1" :headers="headersBanks" :items="itemsBanks" v-model="selected" @click:row="rowClick" item-key="Line">
                                <template v-slot:item.Action="{ item }">
                                    <v-tooltip bottom="">
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon color="error" @click="removeItem(item)">
                                                <v-icon style="font-size: 16px !important">fas fa-times</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Delete</span>
                                    </v-tooltip>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-row> -->
                    <!-- </v-col> -->
                </template>
                <template v-slot:ProviderBloqueado="{ row }">
                    <v-chip x-small :color="row.ProviderBloqueado == 0 ? 'info': 'error'">
                        {{ row.ProviderBloqueado == 0 ? 'No' : 'Si' }}
                    </v-chip>

                </template>
                <template v-slot:Edit="{ row }">
                    <v-btn x-small elevation="0" class="capitalize" @click="edit(row)">Editar</v-btn>
                </template>
            </s-crud>
        </v-col>
    </v-row>

    <div v-if="dialog">
        <v-dialog class="elevation-0" v-model="dialog" width="100%" persistent transition="dialog-bottom-transition">
            <lgs-provider-new :view="true" :value="SupplierEdit.PrvID" @close="dialog = false"></lgs-provider-new>
        </v-dialog>
    </div>

    <modal-loading :processing="processing" :messageProcessing="messageProcessing"></modal-loading>

</div>
</template>

<script>
import _sSupplier from '@/services/Logistics/SupplierService'
import _sSunarp from "@/services/General/GenSunarpService";

//Components
import LgsProviderNew from "@/views/Logistics/LgsProviderNew";

export default {
    components: {LgsProviderNew},
    props: {},

    data: () => ({
        processing: false,
        messageProcessing: "",
        filter: {},
        config: {
            model: {
                PrvID: "ID",
                ProviderBloqueado: "string",
                Edit:""
            },
            service: _sSupplier,
            headers: [
                {
                    text: "Editar",
                    value: "Edit",
                    sortable: false
                },
                {
                    text: "Ruc",
                    value: "SupID",
                    sortable: false
                },
                {
                    text: "Proveedor",
                    value: "SupName",
                    sortable: false
                },
                {
                    text: "Contacto",
                    value: "ConName",
                    sortable: false
                },
                {
                    text: "Email",
                    value: "ConEmail",
                    sortable: false
                },
                {
                    text: "Celular",
                    value: "ConTelephone",
                    sortable: false
                },
                {
                    text: "L. Negra",
                    value: "ProviderBloqueado",
                    sortable: false
                },

            ],
        },
        /* itemsBanks: [],
        itemsBanksRemoved: [], */
        /* headersBanks: [{
                text: "Action",
                value: "Action",
                width: 10
            },
            {
                text: "Banco",
                value: "TypeBankName"
            },
            {
                text: "Cuenta",
                value: "PbkBill"
            },
            {
                text: "CCI",
                value: "PbkCci"
            }
        ], */
        //selected: [],
        /* itemBank: {
            Line: 0,
            objBank: 0,

            PbkBill: null,
            PbkCci: null
        }, */
        dialog:false,
        SupplierEdit: {},

    }),
    methods: {

        rowSelected(item) {

            if (item.length > 0 && item !== null) {
                this.selected = item;
                /* this.itemsBanks = item[0].itemsBanks
                console.log(item) */
            }

        },

         edit(row) {
            console.log(row)
            this.SupplierEdit = row !== null ? row : null;
            this.dialog = true;
        },

        /* rowClick: function (item, row) {
            this.selected = [item];
        }, */

        /* add() {
            console.log(this.itemsBanks)
            if (
                this.itemsBanks.find(
                    (x) =>
                    x.PbkBill == this.itemBank.PbkBill
                )
            ) {
                this.$fun.alert("Cuenta Nro. " + this.itemBank.PbkBill + " ya fue agregada", "warning");
                return;
            }

            if (this.itemBank.PbkBill.length == 0) {
                this.$refs.PbkBill.error("Ingrese Nro. Cuenta");
                return
            }

            if (this.itemBank.PbkCci.length == 0) {
                this.$refs.PbkCci.error("Ingrese CCI");
                return
            }

            if (this.itemBank.Line == 0) {

                let itemBank = {
                    TypeBank: this.itemBank.objBank == null ? "" : this.itemBank.objBank.DedValue,
                    TypeBankName: this.itemBank.objBank == null ? "" : this.itemBank.objBank.DedDescription,
                    PbkBill: this.itemBank.PbkBill,
                    PbkCci: this.itemBank.PbkCci,
                    SecStatus: 1,
                    UsrCreateID: this.$fun.getUserID()
                };

                this.itemsBanks.push(itemBank);
                this.selected = [itemBank];

            } else {

                this.itemsBanks[this.itemBank.Line - 1].TypeBank = this.itemBank.objBank == null ? "" : this.itemBank.objBank.DedValue;
                this.itemsBanks[this.itemBank.Line - 1].TypeBankName = this.itemBank.objBank == null ? "" : this.itemBank.objBank.DedDescription;
                this.itemsBanks[this.itemBank.Line - 1].TypeBank = this.itemBank.TypeBank;
                this.itemsBanks[this.itemBank.Line - 1].PbkBill = this.itemBank.PbkBill;
                this.itemsBanks[this.itemBank.Line - 1].SecStatus = 1;
                this.itemsBanks[this.itemBank.Line - 1].UsrCreateID = this.$fun.getUserID()

                this.selected = [this.itemsBanks[this.itemBank.Line - 1]];
            }

            for (let i = 0; i < this.itemsBanks.length; i++) this.itemsBanks[i].Line = i + 1;
            this.itemBank.Line = 0

        }, */

        //editar item
       /*  edit() {
            if (this.selected.length > 0) {
                //this.editItem = true;
                let row = this.selected[0];

                this.itemBank.TypeBank = row.TypeBank;
                this.itemBank.PbkBill = row.PbkBill;
                this.itemBank.PbkCci = row.PbkCci;
                this.itemBank.Line = row.Line;
                this.itemBank.PbkID = row.PbkID;

            }
        },

        removeItem(item) {
            console.log(item)
            if (this.itemsBanks.length > 0) {
                if (item.PbkID > 0) {
                    item.SecStatus = 0;
                    this.itemsBanksRemoved.push(item);
                }
                this.itemsBanks = this.itemsBanks.filter((x) => x != item);
                this.selected = this.itemsBanks.length > 0 ? [this.itemsBanks] : [];
            }
        },

        clear() {
            this.itemsBanks = []
        }, */

        searchSunarp(item) {

            if (item.ProviderRUC == null || item.ProviderRUC.length == 0) {
                return
            }

            console.log(item)

            this.messageProcessing = ""
            this.processing = true
            _sSunarp.searchsunarp(item.ProviderRUC, this.$fun.getUserID()).then(resp => {
                    console.log(resp.data)
                    if (resp.status == 200) {
                        item.SupName = resp.data.SunName
                        this.processing = false
                    }
                },
                (e) => {
                    this.messageProcessing = e.response.data.Message;
                    this.processing = false;
                    item.SupName = ""
                });

        },

        save(item) {
            if (item.ProviderRUC.length == 0 || item.ProviderRUC == null) {
                this.$refs.ProviderRUC.error("Ingrese Documento")
                return
            }

            if (item.ProviderRUC.length < 8) {
                this.$refs.ProviderRUC.error("Documento invalido")
                return
            }

            console.log(item.SupName)

            if (item.SupName.toString().length == 0 || item.SupName == null) {
                this.$refs.SupName.error("Ingrese Nombre")
                return
            }
            if (item.ProviderAddress == null) {
                this.$refs.ProviderAddress.error("Ingrese Dirección")
                return
            }
            if (item.ConName.toString().length == 0 || item.ConName == null) {
                this.$refs.ConName.error("Ingrese Contacto")
                return
            }
            if (item.ConEmail.toString().length == 0 || item.ConEmail == null) {
                this.$refs.ConEmail.error("Ingrese Email")
                return
            }
            if (!this.$fun.isValidEmail(item.ConEmail)) {
                this.$refs.ConEmail.error("Email invalido")
                return
            }

            if (item.ConTelephone.toString().length == 0 || item.ConTelephone == null) {
                this.$refs.ConTelephone.error("Ingrese Celular")
                return
            }
            item.ProviderName = item.SupName
            item.ProviderTelefono = item.ConTelephone
            item.ProviderEmail = item.ConEmail
            item.SecStatus = 1
            item.UsrCreateID = this.$fun.getUserID();
            //item.itemsBanks = [...this.itemsBanks, ...this.itemsBanksRemoved]

            console.log(item)
            item.save()
        }
    },
};
</script>
